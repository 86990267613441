<template>
    <div>
        <div class="mb-4 header-fixed">
            <div class="container-fluid">
                <div class="section">
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <!-- School Information -->
                            <div>
                                <div class="d-block bg-light rounded-lg p-md-4 p-3">
                                    <div class="row row-sm align-items-center">
                                        <div class="col-md-12 mb-3 pb-2 border-bottom">
                                            <div class="text-dark d-flex align-items-center">
                                                <h5><span class="fe fe-users mr-2"></span>Progress Kemajuan Mahasiswa</h5>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="d-block">                                                
                                                <div class="row ml-1">
                                                    <i style="width:20px" class="btn btn-sm m-0 p-0 btn-secondary mr-1">1</i>Ujian Persiapan &emsp;&emsp;
                                                    <i style="width:20px" class="btn btn-sm m-0 p-0 btn-danger mr-1">2</i>Penyusunan Proposal &emsp;&emsp;
                                                    <i style="width:20px" class="btn btn-sm m-0 p-0 btn-warning mr-1">3</i>Seminan Kemajuan I &emsp;&emsp;
                                                    <i style="width:20px" class="btn btn-sm m-0 p-0 btn-info mr-1">4</i>Seminar Kemajuan II &emsp;&emsp;
                                                    <i style="width:20px" class="btn btn-sm m-0 p-0 btn-primary mr-1">5</i>Seminar Kemajuan III &emsp;&emsp;
                                                    <i style="width:20px" class="btn btn-sm m-0 p-0 btn-success mr-1">6</i>Seminar Kemajuan IV &emsp;&emsp;
                                                    <i style="width:20px" class="btn btn-sm m-0 p-0 btn-light mr-1">7</i>Sidang Doctor
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="d-block">                                                
                                                <div class="row">
                                                    <!-- Address List -->
                                                    <div class="col-md-12">
                                                        <div class="d-block py-3">
                                                            <!-- Tanpa Kode Akses -->
                                                            <div v-if="dataPengguna.length == 0" class="p-2 card mb-3">
                                                                <div class="mt-3 mb-3 row justify-content-center">
                                                                    <div class="card shadow-none pd-20 mx-auto wd-300 text-center bd-1 align-self-center">
                                                                    <div class="text-center">
                                                                    <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/431f98add25ded6563ab086d/logo_itb_1024.png" class="rounded-circle img-fluid wd-60 mg-y-20" alt="">			   
                                                                    </div>
                                                                    <div class="clearfix">
                                                                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                                                                            <div class="alert alert-info" role="alert">
                                                                                Silahkan Masukan <b>KODE AKSES</b> yang diberikan oleh Admin
                                                                            </div>
                                                                            <div class="form-group input-group">
                                                                                <div class="input-group-prepend">
                                                                                    <div class="input-group-text"><i class="fa fa-lock"></i></div>
                                                                                </div>
                                                                                <input type="text" v-model="form.akses" class="form-control" placeholder="Kode Akses..." required="">
                                                                            </div>
                                                                            <div v-if="alertFailed == 1" class="webnots-failure webnots-notification-box">Kode Akses Salah! ({{ countFailed }}x)</div>
                                                                            <div class="input-group mg-y-20">
                                                                                <button class="col-12 btn btn-success">Lihat Progress</button>
                                                                            </div>
                                                                    </form>
                                                                    </div>
                                                                </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <!-- Dengan Kode Akses -->
                                                            <div v-if="dataPengguna.length > 0" class="p-2 card mb-3">
                                                                <div class="mt-2 row">
                                                                    <div class="col-md-4 col-sm-12">
                                                                        <div class="input-group input-group-flush input-group-merge">
                                                                            <div class="input-group-prepend">
                                                                                <div class="input-group-text">
                                                                                    <span class="fe fe-users mr-2"></span>{{ totalPengguna }} Mahasiswa
                                                                                </div>
                                                                            </div>
                                                                            <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getKemajuan()" placeholder="Cari Mahasiswa...">
                                                                            <div class="input-group-prepend">
                                                                                <div class="input-group-text">
                                                                                    <span class="fe fe-search"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!-- HEADER -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-3 col-sm-12">
                                                                        <select @change.prevent="getKemajuan()" v-model="filterPengutamaan" class="form-control">
                                                                            <option value="">- Semua Pengutamaan -</option>
                                                                            <option v-for="(item, index) in dataPengutamaan" :key="index" :value="item.id">{{ item.nama_pengutamaan }}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-md-3 col-sm-12">
                                                                        <select @change.prevent="getKemajuan()" v-model="filterTahapan" class="form-control">
                                                                            <option value="">- Semua Tahapan -</option>
                                                                            <option v-for="(item, index) in dataTahapan" :key="index" :value="item.id">{{ item.nama_kegiatan }}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-md-2 col-sm-12">
                                                                        <download-excel :fetch="getDataExport" :name="fileName" :header="header">
                                                                            <button class="btn btn-success text-sm"><span class="fe fe-download mr-2"></span>Export</button>
                                                                        </download-excel>
                                                                    </div>
                                                                </div>
                                                                <div class="mt-2 table-responsive">
                                                                    <table class="table table-xl table-responsive-sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>No</th>
                                                                                <th>NIM</th>
                                                                                <th>NAMA</th>
                                                                                <th>PEMBIMBING</th>
                                                                                <th>Tahapan Lulus</th>
                                                                                <th>Studi Berjalan</th>
                                                                                <th class="text-center text-dager">Batas Maksimal<br> Selanjutnya</th>
                                                                                <th>Studi Maksimal</th>
                                                                                <th>Aksi</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(user, index) in dataPengguna" :key="index">
                                                                                <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                                                                <td>
                                                                                    {{ user.nim }}
                                                                                    <span v-if="user.late == 0" class='notification-count wave in badge badge-danger'><i class="fe fe-alert-triangle"></i></span>
                                                                                    <span v-if="user.late == 2" class='notification-count wave in badge badge-success'><i class="fe fe-thumbs-up"></i></span>
                                                                                </td>
                                                                                <td>
                                                                                    {{ user.name }}<br>
                                                                                    <span class="pt-0 pb-0 btn btn-sm btn-oblong btn-outline-info">{{ user.pengutamaan }}</span>
                                                                                </td>
                                                                                <td>
                                                                                    {{ user.pembimbing[0] }}<br>
                                                                                    {{ user.pembimbing[1] }}<br>
                                                                                    {{ user.pembimbing[2] }}
                                                                                </td>
                                                                                <td>
                                                                                    <span v-if="user.id_tahapan == 1" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-secondary btn-block mg-b-10">{{ user.tahapan }}</span>
                                                                                    <span v-if="user.id_tahapan == 2" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-danger btn-block mg-b-10">{{ user.tahapan }}</span>
                                                                                    <span v-if="user.id_tahapan == 3" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-warning btn-block mg-b-10">{{ user.tahapan }}</span>
                                                                                    <span v-if="user.id_tahapan == 4" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-info btn-block mg-b-10">{{ user.tahapan }}</span>
                                                                                    <span v-if="user.id_tahapan == 5" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-primary btn-block mg-b-10">{{ user.tahapan }}</span>
                                                                                    <span v-if="user.id_tahapan == 6" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-success btn-block mg-b-10">{{ user.tahapan }}</span>
                                                                                    <span v-if="user.id_tahapan == 7" @click.prevent="progressForm(user.id, user.nim, user.name)" class="btn btn-sm btn-oblong btn-light btn-block mg-b-10">{{ user.tahapan }}</span>
                                                                                </td>
                                                                                <td>{{ user.progress_studi }} Semester</td>
                                                                                <td class="text-center ">
                                                                                    <span v-if="user.next != 'Selesai' && user.undri == 0" class="text-danger">{{ user.next }}</span>
                                                                                    <span v-if="user.next == 'Selesai' && user.undri == 0" class="text-success">{{ user.next }}</span>
                                                                                    <span v-if="user.undri == 1" class="text-danger">Undur Diri</span>
                                                                                </td>
                                                                                <td>{{ user.batas_studi }}</td>
                                                                                <td class="text-center">
                                                                                    <button @click.prevent="editForm(user.id)" class="btn btn-secondary btn-sm mr-2"><i class="fe fe-eye"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="card-footer border-top-0">
                                                                    <div style="float:right">
                                                                        <!-- <ul class="pagination mb-0"></ul> -->
                                                                        <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                                                    :page-count="pagination.totalPages"
                                                                                    :classes="pagination.bootstrapPaginationClasses"
                                                                                    :labels="pagination.paginationAnchorTexts"
                                                                                    v-on:change="getData()"></v-pagination>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Detail Kemajuan
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <div class="row align-items-center">
                                <label class="col-12">Nama Lengkap</label>
                                <div class="col-12">
                                    <input type="text" disabled v-model="kemajuan.name" value="" class="form-control" placeholder="" required="">
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group">
                            <div class="row align-items-center">
                                <label class="col-6">Awal Studi</label>
                                <label class="col-6">Tahapan</label>
                                <div class="col-6">
                                    <div class="input-group">
                                        <select class="col-6 form-control" required="" disabled v-model="kemajuan.awal_studi_bulan">
                                            <option value="01">Januari</option>
                                            <option value="06">Juni</option>
                                        </select>
                                        <input type="number" disabled v-model="kemajuan.awal_studi_tahun" value="" class="form-control" placeholder="2023" required="">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <select class="form-control" required="" disabled v-model="kemajuan.id_tahapan">
                                        <option value="">- Pilih Tahapan -</option>
                                        <option v-for="(item, index) in dataTahapan" :key="index" :value="item.id">{{ item.nama_kegiatan }}</option>
                                    </select>
                                </div>
                                <label class="mt-2 col-12">Pembimbing</label>
                                <div class="col-12">
                                    <textarea v-model="kemajuan.list_pembimbing" disabled required="" class="form-control"></textarea>
                                </div>
                                <label class="mt-2 col-12">Judul</label>
                                <div class="col-12">
                                    <textarea v-model="kemajuan.judul" required="" disabled class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer border-top-0">
                        <ul class="pagination mb-0"></ul>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Tutup</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="detail-tahapan" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Detail Progress Kemajuan
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <div class="row align-items-center">
                                <div class="col-3">
                                    <input type="text" disabled v-model="detail.nim" value="" class="form-control" placeholder="" required="">
                                </div>
                                <div class="col-9">
                                    <input type="text" disabled v-model="detail.name" value="" class="form-control" placeholder="" required="">
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group">
                            <div v-for="(item, index) in detailTahapan" :key="index" class="baseline baseline-border">
                                <div class="baseline-list baseline-border baseline-success">
                                    <div class="baseline-info">
                                    <span class="mb-0 tx-gray-500">{{ convertDate(item.tanggal) }}</span>
                                    <h2 class="tx-16 tx-dark">{{ item.tahapan }}</h2>
                                    </div>
                                </div>
                            </div>
                            <div v-if="detailTahapan == null" class="alert alert-info alert-bordered pd-y-15" role="alert">
                                <div class="d-sm-flex align-items-center justify-content-start">
                                    <i class="icon ion-ios-information alert-icon tx-52 mg-r-20"></i>
                                    <div class="mg-t-20 mg-sm-t-0">
                                    <h5 class="mg-b-2">Belum Ada Progress yang dilalui.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer border-top-0">
                        <ul class="pagination mb-0"></ul>
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-text text-muted" @click="hide_detail()">Tutup</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import moment from 'moment';
    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Data Kemajuan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                fileName: "Kemajuan Mahasiswa",
                header: "Progress Data Kemajuan Mahasiswa",
                dataPengguna: [],
                dataTahapan: [],
                dataPengutamaan: [],
                detailTahapan: null,
                fetching: true,
                checkPassword: 0,
                filterTahapan: '',
                filterPengutamaan: '',
                alertFailed: 0,
                countFailed: 0,
                form: {
                    akses: ''
                },
                detail:{
                    nim: '',
                    name: '',
                },
                kemajuan:{
                    id : '',
                    name : '',
                    id_tahapan : '',
                    awal_studi_bulan : '',
                    awal_studi_tahun : '',
                    list_pembimbing : '',
                    judul : '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 7,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            
        },
        components: {
            vPagination
        },
        methods: {
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            hide_detail() {
                this.$modal.hide('detail-tahapan')
            },
            // Get data data periode
            getKemajuan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'website/general/kemajuan', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        filterTahapan: this.filterTahapan,
                        filterPengutamaan: this.filterPengutamaan,
                    }
                })
                    .then((response) => {
                        this.dataPengguna  = response.data.data;
                        this.totalPengguna = response.data.total;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },

            getTahapan() {
                this.$http.get(this.baseUrl + 'website/general/tahapan', {
                    params: {
                        keywords: '',
                    }
                })
                    .then((response) => {
                        this.dataTahapan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            getPengutamaan() {
                this.$http.get(this.baseUrl + 'website/general/pengutamaan', {
                    params: {
                        keywords: '',
                    }
                })
                    .then((response) => {
                        this.dataPengutamaan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `website/general/detail?id=${id}`)
                    .then((response) => {
                        this.kemajuan.id = response.data.data[0].id;
                        this.kemajuan.name = response.data.data[0].name;
                        this.kemajuan.id_tahapan = response.data.data[0].id_tahapan;
                        this.kemajuan.awal_studi_bulan = response.data.data[0].awal_studi_bulan;
                        this.kemajuan.awal_studi_tahun = response.data.data[0].awal_studi_tahun;
                        this.kemajuan.list_pembimbing = response.data.data[0].list_pembimbing;
                        this.kemajuan.judul = response.data.data[0].judul;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            progressForm(id, nim, name) {
                this.$http.get(this.baseUrl + `website/general/progress?id=${id}`)
                    .then((response) => {
                        this.detailTahapan  = response.data.data;
                        this.detail.nim     = nim;
                        this.detail.name    = name;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
                this.$modal.show('detail-tahapan');
            },

            submitForm() {
                this.$http.post(this.baseUrl + 'website/general/akses', this.form)
                    .then((response) => {
                        if(response.data.status){
                            this.getTahapan();
                            this.getPengutamaan();
                            this.getKemajuan();
                        }else{
                            this.countFailed = this.countFailed+1;
                            this.alertFailed = 1;
                        }
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },

            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').lang("id").format('DD MMMM YYYY');
            },

            async getDataExport() {
                const response = await this.$http.get(this.baseUrl + `website/general/export`, {
                    params: {
                        filterTahapan: this.filterTahapan,
                        filterPengutamaan: this.filterPengutamaan,
                        keywords: this.keywords,
                    }
                });
                return response.data.data;
            }
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>