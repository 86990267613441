import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/admin/authentication/Signin.vue';
import Beranda from '@/views/siswa/Beranda.vue';
import VueMeta from 'vue-meta';
import Axios from 'axios';
import configuration from './configuration';
import VueBodyClass from 'vue-body-class';

import Home from '@/views/admin/dashboard/Dashboard.vue';
import SideBar from '@/layouts/SideBar.vue';
import MainWebsite from '@/layouts/MainWebsite.vue';
import PageNotFound from '@/layouts/PageNotFound.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [{
        path: '/sign-admin',
        name: 'login',
        component: Login
    },
    {
        path: '/admin/dashboard',
        name: 'admin.dashboard',
        component: Home,
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/pengguna',
        name: 'master.pengguna',
        component: () => import('@/views/admin/master/MasterPengguna.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/dosen',
        name: 'master.dosen',
        component: () => import('@/views/admin/master/MasterDosen.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/tahapan',
        name: 'master.tahapan',
        component: () => import('@/views/admin/master/MasterTahapan.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/pengutamaan',
        name: 'master.pengutamaan',
        component: () => import('@/views/admin/master/MasterPengutamaan.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/akses',
        name: 'master.akses',
        component: () => import('@/views/admin/master/MasterAkses.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    // DATA MASTER MAGISTER
    {
        path: '/admin/master/pengguna',
        name: 'master.pengguna-magister',
        component: () => import('@/views/admin/master/MasterPengguna_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/dosen',
        name: 'master.dosen-magister',
        component: () => import('@/views/admin/master/MasterDosen_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/tahapan',
        name: 'master.tahapan-magister',
        component: () => import('@/views/admin/master/MasterTahapan_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/pengutamaan',
        name: 'master.pengutamaan-magister',
        component: () => import('@/views/admin/master/MasterPengutamaan_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/master/akses',
        name: 'master.akses-magister',
        component: () => import('@/views/admin/master/MasterAkses_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    // DATA MASTER MAGISTER
    {
        path: '/admin/timeline',
        name: 'Timeline',
        component: () => import('@/views/admin/timeline/Timeline.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/timeline/new',
        name: 'Timeline.new',
        component: () => import('@/views/admin/timeline/Timeline_new.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/timeline',
        name: 'Timeline.magister',
        component: () => import('@/views/admin/timeline/Timeline_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/kemajuan',
        name: 'Kemajuan',
        component: () => import('@/views/admin/kemajuan/Kemajuan.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/kemajuan',
        name: 'Kemajuan.magister',
        component: () => import('@/views/admin/kemajuan/Kemajuan_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator', 'tu']
        }
    },
    {
        path: '/admin/kemajuan/new',
        name: 'Kemajuan.new',
        component: () => import('@/views/admin/kemajuan/Kemajuan_new.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/lulus',
        name: 'Lulus',
        component: () => import('@/views/admin/lulus/Lulus.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/lulus',
        name: 'Lulus.magister',
        component: () => import('@/views/admin/lulus/Lulus_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/progress',
        name: 'Progress',
        component: () => import('@/views/admin/progress/Progress.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/progress',
        name: 'Progress.magister',
        component: () => import('@/views/admin/progress/Progress_magister.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },
    {
        path: '/admin/tracer',
        name: 'Tracer',
        component: () => import('@/views/admin/tracer/Tracer.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','tu']
        }
    },

    /* -------------------------------------------------------------- */
    /* Router Siswa */
    {
        path: '/',
        name: 'home',
        component: Beranda,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Beranda,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Beranda,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/sign-in',
        name: 'login',
        component: () => import('@/views/siswa/SignIn.vue')
    },
    {
        path: '/sign-up',
        name: 'signup',
        component: () => import('@/views/siswa/SignUp.vue'),
    },
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./views/admin/authentication/Signout.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/progress/kemajuan',
        name: 'progress',
        component: () => import('@/views/siswa/KemajuanMahasiswa.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa','mahasiswa']
        }
    },
    {
        path: '/progress/kemajuan-magister',
        name: 'progress.magister',
        component: () => import('@/views/siswa/KemajuanMahasiswaMagister.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa','mahasiswa']
        }
    },
    {
        path: '/mahasiswa/profil',
        name: 'profil',
        component: () => import('@/views/siswa/ProfilMahasiswa.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa','mahasiswa']
        }
    },
    {
        path: '/mahasiswa/profil-magister',
        name: 'profil.magister',
        component: () => import('@/views/siswa/ProfilMahasiswaMagister.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa','mahasiswa']
        }
    },
    {
        path: '/mahasiswa/tracer',
        name: 'tracer',
        component: () => import('@/views/siswa/TracerStudy.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa','mahasiswa']
        }
    },
    {
        path: '/mahasiswa/tracer-magister',
        name: 'tracer',
        component: () => import('@/views/siswa/TracerStudyMagister.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite,
            roles : ['siswa','mahasiswa']
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: PageNotFound,
    },      
];

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["login", "signup", "home"];
    const authRequired = !publicPages.includes(to.name);
    const email = localStorage.getItem('email');

    // if user login without twofactor authentication
    if (!email) {
        const token = localStorage.getItem('auth_token');
        if (authRequired && token) {
            Axios.get(configuration.host + 'auth/validate_user', {
                headers: { 'Authorization': token }
            }).then(response => {
                if (!response.data.status) {
                    localStorage.removeItem('id');
                    localStorage.removeItem('auth_token');
                    next('/');
                } else {
                    let roles = response.data.role;
                    
                    if(to.meta.roles.includes(roles)) {
                        next();
                    } else {
                        console.log(to.meta.roles);
                        alert("Akses tidak diperbolehkan");
                        next({ name: 'home' });
                    }
                }
            }).catch(function(error) {
                console.debug(error);
            });
        } else if (authRequired && !token) {
            next({ name: 'home' });
        } else {
            next();
        }
    }

    vueBodyClass.guard(to, next);
});

export default router;