<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- HEADER -->
                <!-- <button type="button" id="myBtn">Open Modal</button> -->
                <div class="d-block mb-4">
                    <div class="d-flex mt-4 p-3 alert alert-success text-dark rounded align-items-center">
                        <div class="mr-3"><img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/49b8c8dbfc6dd21bfd99a6c5/3379693.png" width="60"></div>
                        <div>
                            <h4 style="color:black !important" class="text-bold text-dark mb-2">Data Kemajuan Mahasiswa</h4>
                            <p class="mb-2">layanan sistem informasi Progres Kemajuan Mahasiswa</p>
                            <p v-if="userdata.role == 'administrator'" class="text-sm m-0">Periode : <a class="text-primary">Semester 1 </a>-<a class="text-primary"> Semester 14</a></p>
                            <p v-if="userdata.role == 'tu'" class="text-sm m-0">Periode : <a class="text-primary">Semester 1 </a>-<a class="text-primary"> Semester 8</a></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row row-xs clearfix">

            <div class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-white">
                    <div class="card-body pd-y-0">
                        <div class="bg-white custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Total&nbsp;Mahasiswa</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[0].mahasiswa }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- PROGRESS MAGISTER -->

            <div v-if="userdata.role == 'tu'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-secondary">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#6c757d7d" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Seminar&nbsp;I&nbsp;(proposal)</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[0].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[0].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'tu'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-danger">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#c7301b87" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Seminar&nbsp;II&nbsp;(progress)</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[1].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[1].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'tu'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-warning">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#e8b31987" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Sidang&nbsp;Tesis</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[2].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[2].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- PROGRESS MAGISTER -->

            <!-- PROGRESS DOCTOR -->

            <div v-if="userdata.role == 'administrator'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-secondary">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#6c757d7d" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Ujian&nbsp;Persiapan</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[0].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[0].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'administrator'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-danger">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#c7301b87" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Penyusunan&nbsp;Proposal</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[1].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[1].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'administrator'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-warning">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#e8b31987" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Seminan&nbsp;Kemajuan&nbsp;I</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[2].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[2].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'administrator'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-info">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#439bcb87" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Seminan&nbsp;Kemajuan&nbsp;II</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[3].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[3].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'administrator'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-primary">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#4045dd87" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Seminan&nbsp;Kemajuan&nbsp;III</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[4].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[4].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'administrator'" class="col-sm-12 col-xl-3">
                <div class="card mg-b-20  border-success">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#40dd4c87" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Seminan&nbsp;Kemajuan&nbsp;IV</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[5].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[5].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.role == 'administrator'" class="col-sm-12 col-xl-3">
                <div style="border-color:#d2d9d287" class="card mg-b-20">
                    <div class="card-body pd-y-0">
                        <div style="background-color:#d2d9d287" class="custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">Sidang Doctor</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                                    <i class="fa fa-users tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataKemajuan[6].count }} <a style="font-size:12px">Mahasiswa</a></span></h2>
                                    <div class="d-flex align-items-center tx-dark-500">{{ dataKemajuan[6].persen }} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- PROGRESS DOCTOR -->
        </div>

        <hr>

        <div class="row row-xs clearfix">

            <div v-for="(item, index) in dataPengutamaan" :key="index" class="col-sm-6 col-xl-2">
                <div class="card mg-b-20  border-primary">
                    <div class="card-body">
                        <div class="media d-inline-flex">
                            <div>
                                <span class="tx-uppercase tx-10 mg-b-10">{{ item.name }}</span>					  
                                <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ item.count }}</span></h2>
                            </div>
                        </div>
                        <div class="clearfix"> 
                            <span class="float-left tx-11 tx-dark-500">Mahasiswa</span> 
                            <span class="float-right">
                                <span class="tx-dark">{{ item.persen }} %</span><span class="small mg-b-0"></span>
                            </span>
                        </div>
                        <div class="progress ht-3 op-5">
                            <div v-bind:style="{ width: item.persen + 'px' }" class="progress-bar bg-primary" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Dashboard'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataPengutamaan: [],
                dataKemajuan: [],
                keywords: ''
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData();
            this.getKemajuan();
        },
        components: {

        },
        computed: mapState(['userdata']),
        methods: {
            // Get data data periode
            getData() {
                this.$http.get(this.baseUrl + 'admin/master/pengutamaan/counting', {
                    params: {
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataPengutamaan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            getKemajuan() {
                this.$http.get(this.baseUrl + 'admin/kemajuan/statistik', {
                    params: {
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataKemajuan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
        },
    };
</script>